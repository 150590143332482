<template>
    <div class="w-full bg-cover bg-no-repeat bg-black min-h-screen"
         :style="{backgroundImage:'url(' + require('@/assets/m/bg.webp') + ')'}">
        <div class="absolute top-[10px] right-[20px]">
            <lang-select></lang-select>
        </div>
        <img src="@/assets/m/logo.webp" class="w-[150px] min-h-[63px] block pt-[30px] mx-auto">
        <img src="@/assets/m/team.webp" class="w-[200px] min-h-[38px] block my-[20px] mx-auto">
<!--        <div class="relative mr-[10px]">-->
<!--            <img v-if="locale==='zh_CN' || locale === 'zh_TW'" src="@/assets/m/card.webp" class="w-full"/>-->
<!--            <img v-else src="@/assets/m/long_card.webp" class="w-full"/>-->
<!--            <div class="absolute top-0 left-0 pr-[30px] pl-[50px] pt-[20px]">-->
<!--                <div class="text-[#191919] text-[16px] text-center spec-title font-bold">-->
<!--                    {{ $t('updateTitle') }}-->
<!--                </div>-->
<!--                <div class="text-[14px] mt-[12px]">-->
<!--                    {{ $t('dearUser') }}<br/>{{ $t('updateContent') }}-->
<!--                </div>-->

<!--            </div>-->
<!--            <div class="absolute bottom-[35px] right-[30px] text-[14px] text-[#872626]" @click="$emit('showDesc')">-->
<!--                {{ $t('knowDetail') }}-->
<!--            </div>-->
<!--        </div>-->
        <div class="mx-[13px] pb-[60px]">
            <div
                class="flex justify-between px-[16px] py-[13px] bg-[#4D6080AD] mt-[10px] mb-[20px] items-center rounded-[10px]">
                <div class="flex items-center">
                    <img src="@/assets/m/sub_logo.webp" class="w-[36px] h-[36px]"/>
                    <div class="text-white ml-[10px]">{{ $t('appMore') }}</div>
                </div>
                <div class="bg-[#3370FF] cus-btn  w-fit">
<!--                    <a class="!text-white w-fit" @click="doDownload"-->
<!--                       :href="isIos?getIOSLink():getAndroidLink()">{{ $t('downloadNow') }}</a>-->
                    <a class="!text-white w-fit"
                       href="https://pp88app.com" target="_blank">{{ $t('downloadNow') }}</a>
                </div>
            </div>
            <div v-for="(item,index) in dataList"
                 class="flex justify-between px-[16px] py-[11px] bg-[#4D6080AD] my-[10px] items-center rounded-[10px]"
                 :key="index">
                <div class="flex items-center">
                    <net-signal :level="item.level"></net-signal>
                    <div class="ml-[10px] text-white text-[12px]">
                        <div class="text-[12px]">{{ $t('line') }}{{ index + 1 }}</div>
                        <div class="text-[14px]">{{ item.timeRate }}s</div>
                    </div>
                </div>
                <div class="bg-[#5F52C1] cus-btn" @click="openLink(item.link)">{{ $t('enterNow') }}</div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import NetSignal from "@/components/NetSignal.vue";
import LangSelect from "@/components/LangSelect.vue";

function openLink(link) {
    window.open(link+'?from=nav')
}

// function getEnvironment() {
//     let ua = navigator.userAgent;
//     let result = ''
//     if (/AndroidAPP/i.test(ua)) {
//         result = 'ANDROIDAPPNEW';
//     } else if (/AndroidH5APP/i.test(ua)) {
//         result = 'ANDROIDAPP';
//     } else if (/android/i.test(ua)) {
//         result = 'ANDROID';
//     } else if (window.navigator.standalone) {
//         result = "WEBCLIP";
//     } else if (/(iPhone|iPad|iPod|IOS)/i.test(ua)) {
//         result = 'IOS';
//     } else {
//         // /microMessenger/i.test(ua)
//         result = 'ANDROID';
//     }
//     // Toast('sdf'+ result)
//     return result;
// }

// const isIos = computed(() => {
//     return getEnvironment() === 'IOS' || getEnvironment() === 'WEBCLIP'
// })

function refreshTime() {
    dataList.value.forEach((e, index) => {
        let rand = Math.random() * 3;
        let start = new Date().getTime() - rand;
        setTimeout(() => {
            var image = new Image();
            image.src = e.link + 'speed.jpg?sjs=' + rand;
            image.setAttribute("data-id", index)
            image.onload = () => {
                let end = new Date().getTime();
                let ping = end - start;
                let ss = parseFloat(ping / 1000).toFixed(1);
                if (ss == 0.0) {
                    ss = 0.1;
                }
                e.timeRate = ss;
                if (ss < 1) {
                    e.level = 0;
                } else if (ss >= 1 && ss < 2) {
                    e.level = 1;
                } else if (ss >= 2 && ss < 7) {
                    e.level = 2;
                } else {
                    e.level = 3;
                }
            }
        }, rand)
    })
}

onMounted(() => {
    refreshTime()
    setInterval(() => {
        refreshTime()
    }, 3000);
})

// function getIOSLink() {
//     return 'https://www.pp88app.com/PP88_v3.mobileconfig'
//     // return 'https://m.a51testhk.com/PP88_DEV_HK.mobileconfig'
// }

// function getAndroidLink() {
//     let parse = qs.parse(window.location.search.substring(1));
//     let uCode = parse.u_code || '';
//     return `https://pp88.onelink.me/eggO?af_xp=social&pid=app_share&deep_link_value=${uCode}&af_click_lookback=7d&af_dp=pp88game%3A%2F%2Fwelcome`
// }

// function doDownload() {
//     if (isIos.value) {
//         setTimeout(() => {
//             location.href = "https://www.pp88app.com/embedded3.mobileprovision";
//         }, 3000)
//         // window.open(`https://www.pp88app.com/PP88_v3.mobileconfig`)
//         // window.open(`https://m.a51testhk.com/PP88_DEV_HK.mobileconfig`,'_parent')
//
//         // window.open(`https://m.a51testhk.com/embedded3.mobileprovision`)
//     }
// }

const dataList = ref([
    {name: '线路1', level: 4, timeRate: '0', link: 'https://m.pp88hk.com/'},
    {name: '线路2', level: 4, timeRate: '0', link: 'https://m.pp88a.com/'},
    {name: '线路3', level: 4, timeRate: '0', link: 'https://m.pp88b.com/'},
    {name: '线路4', level: 4, timeRate: '0', link: 'https://m.pp88c.com/'},
    {name: '线路5', level: 4, timeRate: '0', link: 'https://m.pp88d.com/'},
])
</script>
<style>

body {
    margin: 0;
}

p {
    text-indent: 20px;
}
</style>
<style scoped>
.cus-btn {
    min-width: 90px;
    padding: 5px 10px;
    white-space: nowrap;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 15px;
}

:deep(.lang) img {
    height: 26px;
}

:deep(.lang) div {
//font-size: 14px; display: none;
}


.spec-title {
    position: relative;
}

.spec-title:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #872626;
    position: absolute;
    top: 50%;
    transform: translate(-22px, -50%);
}

.spec-title:after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #872626;
    position: absolute;
    top: 50%;
    transform: translate(20px, -50%);
}
</style>
