<template>
    <div class="bg-black w-full min-h-screen bg-cover bg-no-repeat relative"
         :style="{backgroundImage:'url(' + require('@/assets/p/bg.webp') + ')'}">
        <div class="absolute top-[10px] right-[20px]">
            <lang-select></lang-select>
        </div>
        <div class="w-[720px] mx-auto">
            <img src="@/assets/p/logo.webp" class="w-[480px] min-h-[67px] block pt-[57px] mx-auto">
<!--            <div class="relative mt-[27px]">-->
<!--                <img src="@/assets/p/card.webp" class="w-full"/>-->
<!--                <div class="absolute top-0 left-0 pr-[30px] pl-[50px] pt-[20px] h-[70%]">-->
<!--                    <div class="text-[#191919] text-[21px] text-center spec-title font-bold">-->
<!--                        {{ $t('updateTitle') }}-->
<!--                    </div>-->
<!--                    <div class="text-[14px] mt-[12px]">-->
<!--                        {{ $t('dearUser') }}<br/>{{ $t('updateContent') }}-->
<!--                    </div>-->
<!--                    <div class="absolute bottom-[10px] right-[30px] text-[12px] text-[#872626]"-->
<!--                         @click="$emit('showDesc')">{{ $t('knowDetail') }}-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="flex flex-wrap justify-between">
                <div v-for="(item,index) in dataList"
                     class="flex justify-between px-[16px] py-[11px] bg-[#4D6080AD] mt-[20px] items-center rounded-[10px] basis-[44.5%] "
                     :key="index">
                    <div class="flex items-center">
                        <net-signal :level="item.level"></net-signal>
                        <div class="ml-[10px] text-white text-[12px]">
                            <div class="text-[12px]">{{ $t('line') }}{{ index + 1 }}</div>
                            <div class="text-[14px]">{{ item.timeRate }}s</div>
                        </div>
                    </div>
                    <div class="bg-[#5F52C1] cus-btn " @click="openLink(item.link)">{{ $t('enterNow') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import NetSignal from "@/components/NetSignal.vue";
import {onMounted, ref} from "vue";
import LangSelect from "@/components/LangSelect.vue";

// const emit = defineEmits(['showRule', 'btnClick'])
function openLink(link) {
    window.open(link + '?from=nav')
}
const dataList = ref([
    {name: '线路1', level: 4, timeRate: '0', link: 'https://www.pp88hk.com/'},
    {name: '线路2', level: 4, timeRate: '0', link: 'https://www.pp88a.com/'},
    {name: '线路3', level: 4, timeRate: '0', link: 'https://www.pp88b.com/'},
    {name: '线路4', level: 4, timeRate: '0', link: 'https://www.pp88c.com/'},
    {name: '线路5', level: 4, timeRate: '0', link: 'https://www.pp88d.com/'},
])

function refreshTime() {
    dataList.value.forEach((e, index) => {
        let rand = Math.random() * 3;
        let start = new Date().getTime() - rand;
        setTimeout(() => {
            var image = new Image();
            image.src = e.link + 'speed.jpg?sjs=' + rand;
            image.setAttribute("data-id", index)
            image.onload = () => {
                let end = new Date().getTime();
                let ping = end - start;
                let ss = parseFloat(ping / 1000).toFixed(1);
                if (ss == 0.0) {
                    ss = 0.1;
                }
                e.timeRate = ss;
                if (ss < 1) {
                    e.level = 0;
                } else if (ss >= 1 && ss < 2) {
                    e.level = 1;
                } else if (ss >= 2 && ss < 7) {
                    e.level = 2;
                } else {
                    e.level = 3;
                }
            }
        }, rand)
    })
}

onMounted(() => {
    refreshTime()
    setInterval(() => {
        refreshTime()
    }, 3000);
})
</script>

<style scoped>
.cus-btn {
    min-width: 90px;
    padding: 5px 10px;
    white-space: nowrap;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 15px;
}

.spec-title {
    position: relative;
}

.spec-title:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #872626;
    position: absolute;
    top: 50%;
    transform: translate(-22px, -50%);
}

.spec-title:after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #872626;
    position: absolute;
    top: 50%;
    transform: translate(20px, -50%);
}


</style>
